import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AppStore } from "../constants/Stores";
import Navbar from "../components/Navbar";
import { AnimatePresence, motion } from "framer-motion";
import {
  aboutVid,
  beanComb,
  mrAlex,
  mrsMelissa,
  sampleComb,
} from "../constants/palette";
import Menu from "../components/Menu";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import CareModal from "../components/CareModal";

const About = () => {
  const location = useLocation();
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const toggleCare = AppStore((state) => state.toggleCare);
  const isMobile = AppStore((state) => state.isMobile);
  const currentPath = location.pathname?.replace("/", "");
  const layoutRef = useRef();

  useEffect(() => {
    const handleScroll = (event) => {
      if (layoutRef.current) {
        layoutRef.current.scrollLeft += event.deltaY;
      }
    };

    const layoutElement = layoutRef.current;
    layoutElement.addEventListener("wheel", handleScroll);

    return () => {
      layoutElement.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <section
      className={`w-full h-[calc(100dvh_-_75px)] max-sm:h-full flex max-sm:flex-col gap-4 p-3 md:flex-row !overflow-hidden pb-6 md:pt-3 ${
        currentPath === "about" ? (isMobile ? "pt-[70px]" : "mt-[65px]") : ""
      }`}
      id="about"
      ref={layoutRef}
    >
      <Cart />
      <Navbar />
      <CareModal isOpen={isCareOpen} setIsOpen={toggleCare} />
      <AnimatePresence>
        <div className="min-h-full md:!min-w-[100%] flex max-sm:flex-col md:justify-start md:items-center md:gap-6 gap-4 relative overflow-hidden">
          <div className="aboutshow md:w-[80%] h-[100%] max-sm:h-[350px] min-h-[270px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              className="w-full h-full bg-black"
            >
              <video
                className="video "
                autoPlay
                loop
                muted
                playsInline
                preload="auto"
              >
                <source src={aboutVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </div>
          <div
            className={
              isMobile
                ? "font-light text-xl text-center text-primary-red border-2 border-primary-red rounded-[49px] p-4 py-8 px-7 "
                : "max-w-[25%] font-light text-xl text-center text-primary-red border-2 h-full rounded-[49px] border-primary-red items-center justify-center flex px-7"
            }
          >
            <p className="lg:leading-loose lg:text-2xl md:text-lg sm:text-[5px] font-medium ">
              Often overshadowed due to the ubiquity of plastic, combs have lost
              their allure. Modern combs that check boxes for both form and
              function are rare to come across. For so many, the comb is an
              essential item and obsession. Appreciating how design has the
              power to uncover hidden potential in our everyday lives, Orima
              aims to elevate the object cherished by millions. With the
              accumulation of decisions, desires, and days combined, Orima was
              born.
            </p>
          </div>
        </div>
        <div className="md:min-w-[65%] min-h-full max-sm:h-[350px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
          <motion.div
            className="slider-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${mrsMelissa})` }}
          />
        </div>
        {true ? (
          <div className="md:min-w-[25%] min-h-[150px] flex justify-center items-center md:text-2xl text-xl font-bold text-primary-red rounded-[49px] relative overflow-hidden border-2 border-primary-red ">
            <h4>Comb home.</h4>
          </div>
        ) : null}
        <div className="md:min-w-[65%] min-h-full max-sm:h-[350px] rounded-[49px] bg-black relative overflow-hidden border-2 border-primary-red ">
          <motion.div
            className="slider-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{ backgroundImage: `url(${mrAlex})` }}
          />
        </div>
        <div
          className={
            "min-w-[28%] font-bold text-center text-primary-red border-2 border-primary-red rounded-[49px] flex flex-col justify-around md:gap-10 max-sm:gap-4 py-6 px-4 "
          }
        >
          <h3 className="text-2xl ">Orima Combs are Handmade</h3>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-2">
              Strong and Durable
            </h4>
            <p className="lg:text-xl md:text-sm max-sm:text-md font-medium text-center ">
              Cellulose acetate is one of the most durable materials available.
              Its fibers resist breakdown over years of use, making it ideal for
              combs.
            </p>
          </div>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-2">
              Hypoallergenic and Renewable
            </h4>
            <p className="lg:text-xl md:text-sm max-sm:text-md font-medium text-center ">
              Cellulose acetate is hypoallergenic, making it safe for those with
              sensitive skin. It offers a natural feel compared to other
              materials and is derived from renewable plant sources.
            </p>
          </div>
          <div>
            <h4 className="lg:text-xl md:text-sm max-sm:text-md mb-2">
              A Comb for Life
            </h4>
            <p className="lg:text-xl md:text-sm max-sm:text-md font-medium text-center ">
              An Orima comb is an investment in high-quality, Swiss-made,
              Italian cellulose acetate that feels and looks good
            </p>
          </div>
          <div className="w-full flex justify-center">
            <button
              onClick={() => toggleCare()}
              className="round-white-button max-w-[200px]"
            >
              Care
            </button>
          </div>
        </div>
        <div className="overflow-hidden">
          {currentPath == "about" ? <Footer /> : null}
        </div>
      </AnimatePresence>
    </section>
  );
};

export default About;
