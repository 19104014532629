import React, { useEffect, useRef, useState } from "react";
import Home from "./Home";
import Shop from "./Shop";
import Footer from "../components/Footer";
import "./Layout.css";
import { motion, AnimatePresence } from "framer-motion";
import Cart from "../components/Cart";
import { AppStore } from "../constants/Stores";
import CareModal from "../components/CareModal";

import "@fortawesome/fontawesome-free/css/all.min.css";

const Layout = () => {
  const [currentView, setCurrentView] = useState("home");
  const isCartOpen = AppStore((state) => state.isCartOpen);
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const toggleCare = AppStore((state) => state.toggleCare);
  const isMobile = window.innerWidth < 768;
  const layoutRef = useRef();
  const currentScroll = useRef(0);

  useEffect(() => {
    AppStore.setState({ isMobile: isMobile });
  }, []);

  useEffect(() => {
    AppStore.setState({ layoutContainerRef: layoutRef });
  }, []);

  const variants = {
    initial: (direction) => ({
      // x: isMobile ? 0 : direction > 0 ? 1000 : -1000,
      // y: isMobile ? (direction > 0 ? 1000 : -1000) : 0,
      opacity: 0,
    }),
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: isMobile ? 0 : direction > 0 ? -1000 : 1000,
      y: isMobile ? (direction > 0 ? -1000 : 1000) : 0,
      opacity: 0,
    }),
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (layoutRef.current) {
        layoutRef.current.scrollLeft += event.deltaY;
        currentScroll.current = layoutRef.current.scrollLeft;
      }
    };

    const layoutElement = layoutRef.current;
    layoutElement.addEventListener("wheel", handleScroll);

    return () => {
      layoutElement.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div className="layout pt-2 ">
      <CareModal isOpen={isCareOpen} setIsOpen={toggleCare} />
      <div className="views " ref={layoutRef}>
        <AnimatePresence custom={isMobile ? 1 : -1}>
          <motion.div
            key="home"
            id="landingg"
            custom={isMobile ? 1 : -1}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="view"
          >
            <Home />
          </motion.div>
          <motion.div
            key="shop"
            id="shopp"
            custom={isMobile ? -1 : 1}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="view"
          >
            <Shop />
          </motion.div>
        </AnimatePresence>
        <div className="w-[93%] mb-4 self-center overflow-hidden">
          <Footer currentScroll={currentScroll.current} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
