import React, { useState, useMemo, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { primaryContentBackround, arrowGreyPNG } from "../constants/palette";
import { AppStore } from "../constants/Stores";
import { useLocation, useNavigate } from "react-router-dom";

import "./Footer.css";

const mobVariants = {
  visible: { opacity: 1, y: 0 },
  hidd: { opacity: 1, y: -20 },
  hiddPlus: { opacity: 1, y: 0 },
  visiblePlus: { opacity: 1, y: -70 },
};

const deskVariants = {
  visible: { y: 0 },
  hidd: { y: 100 },
  hiddPlus: { y: 0 },
  visiblePlus: { y: 50 },
};

const Footer = ({ currentScroll }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [subEmail, setSubEmail] = useState("");
  const [status, setStatus] = useState("idle");
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const sendNotif = AppStore((state) => state.notify);
  const isMobile = AppStore((state) => state.isMobile);
  const toggleCare = AppStore((state) => state.toggleCare);
  const scrollToView = AppStore((state) => state.scrollToView);

  const handleToggleFooter = () => {
    // scrollToView("foot");

    setIsFooterOpen(!isFooterOpen);
  };

  const handleSubscribe = async () => {
    if (!subEmail) {
      sendNotif("Enter email address.");
      return;
    }
    try {
      setStatus("loading");
      const res = await fetch(`${AppStore.getState().bUrl}/subscribe-news`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: subEmail,
        }),
      });

      console.log("SUBSCRIBE RES", res?.status);
      console.log("SUBSCRIBE RES", res);
      setTimeout(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus("idle");
          sendNotif("Subscribed.");
        }, 1500);
      }, 2500);
    } catch (error) {
      console.log("Error subscribing.");
      setStatus("idle");
      sendNotif("Error occured while subscribing.");
    }
  };
  // alert(isMobile);

  useEffect(() => {
    if (isFooterOpen) {
      const targetElement = document.getElementById("sub-inp");
      // alert(targetElement);
      targetElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      scrollToView("sub-inp");
    }
  }, [isFooterOpen]);

  return (
    <div
      id="foot"
      className={`${
        isMobile ? "relative" : "fixed"
      } w-[99.8%] bottom-0 left-0 z-[100]`}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          className={`flex w-full h-10 ${isFooterOpen ? "absolute " : ""} ${
            isMobile ? "justify-center " : "px-4 justify-between mb-1"
          } text-primary-red text-4xl z-[50] `}
        >
          <h6 onClick={handleToggleFooter} className="hover:cursor-pointer ">
            <a href="#footer">{isFooterOpen ? "-" : "+"}</a>
          </h6>
          {!isMobile ? (
            <h6 onClick={handleToggleFooter} className="hover:cursor-pointer ">
              <a href="#footer">{isFooterOpen ? "-" : "+"}</a>
            </h6>
          ) : null}
        </motion.div>
        {/* FOOOTER CONTENT */}
        {isFooterOpen && (
          <motion.div
            className="max-sm:rounded-[49px] overflow-hidden max-sm:mt-4  max-sm:border-2 border-[#C62625]"
            initial={{ opacity: 0.8 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.8 }}
            transition={{ duration: 0.25 }}
          >
            <motion.div className="max-sm:mt-5">
              <motion.div
                id="footerr"
                variants={isMobile ? mobVariants : deskVariants}
                initial={"hidd"}
                animate={isFooterOpen ? "visible" : "hidd"}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                }}
                className={
                  isMobile
                    ? `${
                        isFooterOpen ? "visible flex" : ""
                      } w-full min-h-[160px] bottom-0  `
                    : `${
                        isFooterOpen
                          ? "visible flex border-2 border-primary-red"
                          : ""
                      } w-full min-h-[170px]  bottom-0 `
                }
                style={{
                  background: "white",
                }}
              >
                <div
                  className={
                    isMobile
                      ? "grid w-full min-h-full justify-evenly pb-10 pt-4 text-md text-primary-red px-6 "
                      : "flex w-full min-h-full justify-evenly pb-2 pt-4 text-md text-primary-red px-6"
                  }
                >
                  <div className="flex w-full gap-8 min-h-full md:justify-around max-sm:justify-between pb-2  text-sm font-bold ">
                    {/* NEWSLETTER */}
                    {!isMobile ? (
                      <div className="flex flex-col mb-4 h-full justify-between font-bold ">
                        <h2 className="text-2xl font-medium"> Newsletter </h2>
                        <div className="flex gap-5 border-b py-1 mb-3 items-center">
                          {/* <img
                            src={arrowGreyPNG}
                            alt="arrow"
                            className="w-4 mt-1 ml-1"
                          /> */}
                          <svg
                            width="19px"
                            height="19px"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                              fill="#C62625"
                            />
                          </svg>
                          <input
                            value={subEmail}
                            placeholder="Enter your email address"
                            className=" bg-transparent outline-none m-auto placeholder:text-primary-red opacity-80  text-primary-red"
                            onChange={(e) => setSubEmail(e.target.value)}
                          />

                          <button
                            className={`subscribe-button ${status}`}
                            onClick={handleSubscribe}
                            disabled={status === "loading"}
                          >
                            {status === "idle" && "Subscribe"}
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {/* LINKS */}
                    <div className="flex flex-col mb-2 h-full justify-between font-bold p-3 !z-1">
                      <h2 className="text-2xl ml-[-5px]"> Links </h2>
                      <div className="grid font-medium max-sm:gap-1">
                        <h6
                          className=" hover:underline hover:cursor-pointer"
                          onClick={() => navigate("/shop")}
                        >
                          SHOP
                        </h6>
                        <h6
                          className=" hover:underline hover:cursor-pointer"
                          onClick={() => navigate("/about")}
                        >
                          ABOUT
                        </h6>
                        <h6
                          className=" hover:underline hover:cursor-pointer"
                          onClick={() => toggleCare()}
                        >
                          CARE
                        </h6>
                      </div>
                    </div>
                    {/* CONTACT */}
                    {!isMobile ? (
                      <div className="flex flex-col mb-2 h-full justify-between  font-bold">
                        <h2 className="text-2xl ml-[-5px]"> Contact </h2>
                        <div className="grid font-medium">
                          <h6 className=" hover:underline hover:cursor-pointer">
                            INFO@ORIMA.WORLD
                          </h6>
                        </div>
                      </div>
                    ) : null}
                    {/* SOCIALS */}
                    {!isMobile ? (
                      <div className="flex flex-col  h-full justify-between font-bold mt-2">
                        <h2 className="text-2xl ml-[-5px]"> Follow </h2>
                        <div
                          className={
                            isMobile
                              ? "grid text-left  pb-0 font-medium"
                              : "grid font-medium"
                          }
                        >
                          <a
                            className=" hover:underline hover:cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/orima.world"
                          >
                            INSTAGRAM
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* CONTACT Mobile */}
                  {isMobile ? (
                    <div className="flex flex-col mb-1 h-full justify-between py-2 font-bold p-3">
                      <h2 className="text-2xl ml-[-5px]"> Contact </h2>
                      <div className="grid font-medium">
                        <h6 className=" hover:underline hover:cursor-pointer">
                          INFO@ORIMA.WORLD
                        </h6>
                      </div>
                    </div>
                  ) : null}
                  {/* SOCIALS */}
                  {isMobile ? (
                    <div className="flex flex-col  h-full justify-between font-bold mt-2 p-3">
                      <h2 className="text-2xl ml-[-5px]"> Follow </h2>
                      <div
                        className={
                          isMobile
                            ? "grid text-left  pb-0 font-medium"
                            : "grid font-medium"
                        }
                      >
                        <a
                          className=" hover:underline hover:cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/orima.world"
                        >
                          INSTAGRAM
                        </a>
                      </div>
                    </div>
                  ) : null}

                  {/* NEWSLETTER MOBILE */}
                  {isMobile ? (
                    <div className="flex flex-col mb-4 h-full justify-center mt-2 mx-2">
                      <h2 className="text-2xl ml-[-5px] mb-1"> Newsletter </h2>
                      <div className="flex gap-5 justify-between border-b border-primary-red py-1 mb-3 items-center max-w-full">
                        <svg
                          width="19px"
                          height="19px"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.3153 16.6681C15.9247 17.0587 15.9247 17.6918 16.3153 18.0824C16.7058 18.4729 17.339 18.4729 17.7295 18.0824L22.3951 13.4168C23.1761 12.6357 23.1761 11.3694 22.3951 10.5883L17.7266 5.9199C17.3361 5.52938 16.703 5.52938 16.3124 5.91991C15.9219 6.31043 15.9219 6.9436 16.3124 7.33412L19.9785 11.0002L2 11.0002C1.44772 11.0002 1 11.4479 1 12.0002C1 12.5524 1.44772 13.0002 2 13.0002L19.9832 13.0002L16.3153 16.6681Z"
                            fill="#C62625"
                          />
                        </svg>

                        <input
                          id="sub-inp"
                          value={subEmail}
                          placeholder="Your email address"
                          className="bg-transparent outline-none m-auto max-w-[165px] placeholder:text-primary-red opacity-80  text-primary-red"
                          onChange={(e) => setSubEmail(e.target.value)}
                        />
                        <button
                          className={`subscribe-button ${status}`}
                          onClick={handleSubscribe}
                          disabled={status === "loading"}
                        >
                          {status === "idle" && "Subscribe"}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Footer;
