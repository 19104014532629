import React from "react";
import "./index.css";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Shop from "./pages/Shop";
import About from "./pages/About";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/about",
    element: <About />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster
      containerStyle={{
        top: 0,
        right: 0,
      }}
      containerClassName="toast-notif"
      toastOptions={{
        className: "",
        style: {
          // position: "absolute",
          right: "0 !important",
          top: "0 !important",
          border: "1px solid #713200",
          padding: "16px",
          color: "#713200",
        },
      }}
    />
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
