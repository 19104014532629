// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Layout.css */
.layout {
  width: 100%;
  /* height: calc(100vh - 4rem); */
  height: calc(100vh);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.views {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 2.5rem;
  /* scroll-snap-type: x mandatory; */
}

.view {
  flex: 0 0 100vw;
  scroll-snap-align: start;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

#home,
#shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.model-loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #c62625;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: -40px;
  animation: spin 1s linear infinite;
}

@media (max-width: 768px) {
  .layout {
    width: 100%;
    /* height: 100%; */
    flex-direction: column;
  }
  .views {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: scroll;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Layout.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,WAAW;EACX,gCAAgC;EAChC,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE;IACE,WAAW;IACX,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* src/Layout.css */\n.layout {\n  width: 100%;\n  /* height: calc(100vh - 4rem); */\n  height: calc(100vh);\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  overflow: hidden;\n}\n\n.views {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  width: 100vw;\n  height: 100vh;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  padding-top: 2.5rem;\n  /* scroll-snap-type: x mandatory; */\n}\n\n.view {\n  flex: 0 0 100vw;\n  scroll-snap-align: start;\n  /* width: 100%; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n#home,\n#shop {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.model-loader {\n  border: 6px solid #f3f3f3;\n  border-top: 6px solid #c62625;\n  border-radius: 50%;\n  width: 60px;\n  height: 60px;\n  margin-left: -40px;\n  animation: spin 1s linear infinite;\n}\n\n@media (max-width: 768px) {\n  .layout {\n    width: 100%;\n    /* height: 100%; */\n    flex-direction: column;\n  }\n  .views {\n    flex-direction: column;\n    overflow-y: scroll;\n    overflow-x: scroll;\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
