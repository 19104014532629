import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AppStore } from "../constants/Stores";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { simpleComb } from "../constants/palette";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51ORMYmJJH4ECTh2QNDOyiSRAcZpH3iRjVBPbgAol1NWMRUAZLjyyBJyrcTcV0UYkPWKcjL8lu8IYnt0Cf13Zt9vB006dm5R0V3"
);

const Cart = () => {
  const cartCount = AppStore((state) => state.cartCount);
  const isCartOpen = AppStore((state) => state.isCartOpen);
  const increaseCart = AppStore((state) => state.addCartCount);
  const decreaseCart = AppStore((state) => state.decreaseCartCount);
  const [cartPage, setCartPage] = useState(0);

  const [clientSecret, setClientSecret] = useState("");

  const totalPrice = cartCount > 0 ? cartCount * 40 + 7 : 0;

  useEffect(() => {
    try {
      // Create PaymentIntent as soon as the page loads
      console.log("Fetching Payment");
      fetch(`${AppStore.getState().bUrl}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items: [{ id: "prod_PGfdUFOogLynZE", amount: cartCount }],
        }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    } catch (error) {}
  }, [cartPage]);

  const appearance = {
    theme: "minimal",
    labels: "floating",
    variables: {
      fontFamily: "Sohne, system-ui, sans-serif",
      fontWeightNormal: "500",
      borderRadius: "8px",
      colorBackground: "white",
      colorPrimary: "#C62625",
      accessibleColorOnColorPrimary: "#1A1B25",
      colorText: "#C62625",
      colorTextSecondary: "#C62625",
      colorDanger: "#C62625",
      // colorTextPlaceholder: "#ABB2BF",
      tabIconColor: "#C62625",
      logoColor: "dark",
    },
    rules: {
      ".Input": {
        backgroundColor: "white",
        border: "1px solid var(--colorPrimary)",
        // fontSize: "14px",
      },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className={`w-screen absolute top-0 right-0 flex justify-center items-center !overflow-hidden z-[100] ${
        isCartOpen ? "h-screen" : " !hidden"
      }`}
    >
      <AnimatePresence>
        {/* <motion.div
          className="absolute w-screen h-screen bg-red-400 z-[69]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        ></motion.div> */}
        {isCartOpen && (
          <motion.div
            initial={{ x: "100%", opacity: 1 }}
            animate={{ x: 0, scale: 1, opacity: 1 }}
            exit={{ x: "100%", opacity: 1 }}
            transition={{ type: "tween", duration: 0.3 }}
            className=" fixed w-full h-[100%] py-8 md:pt-[75px] flex max-sm:justify-center bg-transparent justify-end md:mr-4 items-center text-primary-red z-[70] "
          >
            <motion.div className="max-sm:w-[94%] w-[400px] h-[100%] bg-white border-2 rounded-[49px] flex flex-col items-center pt-24 font-bold z-[1000] relative border-primary-red">
              <div
                onClick={() => (
                  setCartPage(0), AppStore.setState({ isCartOpen: false })
                )}
                className="absolute top-5 hover:cursor-pointer"
              >
                <svg width="28px" height="22px" viewBox="0 0 1024 1024">
                  <path
                    fill="#c62625"
                    d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
                  />
                </svg>
              </div>
              {cartPage == 0 && (
                <div className="w-full h-full flex flex-col items-center p-6">
                  <div className="flex w-full h-[45px] justify-between items-center border-t-2 border-b-2 border-primary-red px-2">
                    <img
                      src={simpleComb}
                      alt="cmb-small"
                      className="w-12 h-12"
                    />
                    <h5 className="min-w-[30px]">{cartCount}x</h5>
                    <h4>BEAN POCKET COMB</h4>
                    <h4>$40</h4>
                  </div>
                  <div className="w-full flex justify-between px-0 py-3">
                    <span className="text-gray-400 text-sm">EDIT CART</span>
                    <div className="flex h-[20px] w-[70px] gap-1 text-white">
                      <button
                        className="w-full h-full text-center items-center flex justify-center pb-[3px] rounded-l-[49px] bg-gray-300"
                        onClick={() => decreaseCart()}
                      >
                        -
                      </button>
                      <button
                        className="w-full h-full text-center items-center flex justify-center pb-[3px] rounded-r-[49px] bg-gray-300"
                        onClick={() => increaseCart()}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex justify-start mt-2">
                    <h4 className="w-full">+ $7 SHIPPING</h4>
                    <div className="w-full flex justify-between">
                      <h4 className=" ">SUBTOTAL</h4>
                      <h4 className=" ">${totalPrice}</h4>
                    </div>
                  </div>
                  <button
                    className={`absolute bottom-14 w-[45%] py-2 rounded-[49px] text-white ${
                      cartCount > 0 ? "bg-primary-red" : "bg-gray-300"
                    } `}
                    onClick={() => setCartPage(1)}
                    disabled={cartCount > 0 ? false : true}
                  >
                    CHECKOUT
                  </button>
                </div>
              )}
              {cartPage == 1 && (
                <div className="w-full h-full flex flex-col items-center">
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm setCartPage={setCartPage} />
                    </Elements>
                  )}
                  {/* <button
                    className="absolute bottom-14 w-[45%] py-3 rounded-[49px] bg-white text-primary-red "
                    onClick={() => setCartPage(0)}
                  >
                    Purchase
                  </button> */}
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Cart;
