import Navbar from "./components/Navbar";
import Layout from "./pages/Layout";
import { AppStore } from "./constants/Stores";
import { Toaster } from "react-hot-toast";

function App() {
  const isMenuOpen = AppStore((state) => state.isMenuOpen);

  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  metaThemeColor.setAttribute("content", "#ffffff");
  return (
    <main className="">
      <Navbar />
      <Layout />
    </main>
  );
}

export default App;
