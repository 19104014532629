export const redLogo = "/Logo/logo-red.png";

export const primaryBackground = "#fffff";
// export const primaryContentBackround = "#ee2d2b";
export const primaryContentBackround = "#C62625";

export const arrowGreyPNG = "/icons/arrow-grey.png";

export const homeVid = "/videos/high.mp4";
export const aboutVid = "/videos/about.mp4";

export const mrAlex = "/images/Alex.png";
export const mrsMelissa = "/images/Melissa.png";

export const beanComb = "/images/bean.jpg";
export const sampleComb = "/images/sample.png";
export const simpleComb = "/images/simple-bean.png";

export const sliderImages = [
  {
    id: 1,
    src: "/images/slider/1.png",
    alt: "slider1",
  },
  {
    id: 2,
    src: "/images/slider/2.png",
    alt: "slider2",
  },
  {
    id: 3,
    src: "/images/slider/3.png",
    alt: "slider3",
  },
  {
    id: 4,
    src: "/images/slider/4.png",
    alt: "slider4",
  },
];
