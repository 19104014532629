import React, { useState } from "react";
import { Link } from "react-scroll";
import { primaryContentBackround, redLogo } from "../constants/palette";
import { AppStore } from "../constants/Stores";
import Menu from "./Menu";
import { useLocation, useNavigate } from "react-router-dom";
import "./nav-motion.css";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cartCount = AppStore((state) => state.cartCount);
  const toggleCart = AppStore((state) => state.toggleCart);
  const toggleCare = AppStore((state) => state.toggleCare);
  const isCareOpen = AppStore((state) => state.isCareOpen);
  const isMenuOpen = AppStore((state) => state.isMenuOpen);
  const scrollToView = AppStore((state) => state.scrollToView);
  const currentPath = location.pathname;

  const [current, setCurrent] = useState(1);

  const nav = [
    { index: 1, name: "Home", href: "/" },
    { index: 2, name: "Shop", href: "/shop" },
    { index: 3, name: "About", href: "/about" },
  ];

  const handleClick = (index) => {
    const newCurrent = index + 1;
    setCurrent(newCurrent);
  };

  const barOuterClasses = `bar-outer pos${current} ${
    current > 1 ? "right" : current < 1 ? "left" : ""
  }`;

  return (
    <header className="fixed top-0 left-0 w-screen z-[90]">
      <nav className="absolute w-full h-[60px] flex justify-between items-center max-sm:px-5 px-7 pt-2 text-primary-red">
        <div className="navigation text-primary-red font-bold relative flex gap-6 ">
          <div className="nav-container text-primary-red font-bold !hidden md:!flex flex-1 min-w-[300px] ">
            {/* <div className="bar" /> */}
            {nav.map((navi, index) => {
              return (
                <Link
                  to="landing"
                  smooth={true}
                  duration={500}
                  // onClick={() => scrollToView("landing")}
                  onClick={() => navigate(navi.href)}
                  key={navi.index}
                  className="min-w-[30px]"
                >
                  <h2
                    className={`font-bold text-md hover:cursor-pointer px-3 pb-1  ${
                      currentPath === navi.href
                        ? "border-2 border-primary-red text-primary-red rounded-[49px]"
                        : ""
                    }`}
                  >
                    {navi.name}
                  </h2>
                </Link>
              );
            })}
          </div>
          {/* <ul className="flex gap-6 max-sm:hidden w-full flex-row ">
            <li>
              <Link
                to="landing"
                smooth={true}
                duration={500}
                // onClick={() => scrollToView("landing")}
                onClick={() => navigate("/")}
              >
                <button>Home</button>
              </Link>
            </li>
            <li>
              <Link
                to="shop"
                smooth={true}
                duration={500}
                // onClick={() => scrollToView("shop")}
                onClick={() => navigate("/shop")}
              >
                <button>Shop</button>
              </Link>
            </li>
            <Link
              to="about"
              smooth={true}
              duration={500}
              // onClick={() => scrollToView("about")}
              onClick={() => navigate("/about")}
            >
              <button>About</button>
            </Link>
          </ul> */}
          <Menu isNavbar={true} isopp={isMenuOpen} />
        </div>
        <div className="logo border-red-300 pb-1">
          <a href="/">
            <img src={redLogo} alt="logo" className="h-8" />
          </a>
        </div>
        <div className="functions ">
          <ul className="flex gap-6 text-primary-red text-lg font-bold flex-row items-center w-full hover:cursor-pointer bg-transparent">
            <li
              className={`max-sm:hidden pb-[2px] ${
                isCareOpen
                  ? "outline bg-primary-red text-white px-1 rounded"
                  : ""
              }`}
              onClick={() => toggleCare()}
            >
              Care
            </li>
            <li
              className="border-2 border-primary-red rounded-full py-[3px] px-[13px] text-sm flex justify-center items-center w-[28px]"
              onClick={() => toggleCart()}
            >
              {cartCount}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
